.color-eth {
  color: $colorETH; }

.bg-primary {
  color: white;
  background-color: $primary; }

.bg-grey {
  color: white !important;
  background-color: lightgrey !important; }

.bg-eth {
  background-color: $colorETH; }

.bg-matic {
  background-color: $colorMATIC; }

.color-bsc {
  color: $colorBSC; }

.bg-bsc {
  background-color: $colorBSC; }
