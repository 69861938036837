.mnemonic-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

.mnemonic-column {
  min-width: 105px; }

.mnemonic-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .MuiFilledInput-root {
    background-color: #fff;
    &:hover {
      background-color: #fff; } }
  .MuiFilledInput-input {
    padding: 10px 2px;
    font-size: 14px; }
  .MuiFilledInput-underline:before,.MuiFilledInput-underline:hover:before,.MuiFilledInput-underline:after, .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom: none; }
  .MuiFilledInput-root.Mui-disabled:hover {
    background-color: rgba(0, 0, 0, 0.12); }
  &-box {
    padding: 6px 2px 0 1px; } }
