.MuiDrawer-paperAnchorLeft {
  background-color: $primary !important;
  color: white !important;
  overflow-x: hidden !important;
  a {
    .MuiTypography-displayBlock,
    .MuiSvgIcon-root,
    .MuiListItemIcon-root {
      color: white !important;
      fill: white; } } }

.MuiAppBar-colorPrimary {
  background-color: white !important; }

.MuiAccordion-root {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 85vw;
  &.Mui-expanded {
    margin-top: 0;
    margin-bottom: 0; } }

svg.MuiCircularProgress-svg {
  color: $primary; }

button {
  svg.MuiCircularProgress-svg {
    color: #ffffff; } }

.MuiButton-containedPrimary.MuiButton-root {
  background: $primary;
  &:active,
  &:hover {
    background: $blue;
    color: white; } }

.MuiButton-text.MuiButton-textPrimary {
  color: $primary;
  &:active,
  &:hover {
    color: white; } }

p.MuiTypography-body1 {
  word-wrap: break-word; }

.MuiBottomNavigationAction-label {
  font-size: 12px !important; }

.MuiAlert-message {
  word-break: break-word; }

.MuiFormControlLabel-root[color="primary"], a[color="primary"] {
  color: $primary; }

.MuiAlert-root {
  margin-top: 10px;
  margin-bottom: 10px; }

.MuiBadge-invisible {
  display: none !important; }

.MuiListItemText-primary {
  color: $primary; }

.MuiListItem-button.active {
  background-color: $primary;
  color: white; }

.MuiCircularProgress-indeterminate {
  .MuiCircularProgress-svg {
    color: $yellow; } }

.MuiMobileStepper-root {
  background-color: white;
  z-index: 3000; }

.MuiSnackbarContent-message {
  max-width: 80%; }
