
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    -webkit-text-size-adjust: 100%; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block; }

html {
    line-height: 1; }

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
    min-height: 100%; }

input,
button,
textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    &:focus {
        outline: none; } }
input {
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    white-space: nowrap;
    &:invalid {
        box-shadow: none; }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        opacity: 0; }
    &::outer-spin-button,
    &::inner-spin-button {
        -webkit-appearance: none;
        opacity: 0; }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
        width: 0;
        height: 0;
        opacity: 0; }
    &::search-decoration,
    &::search-cancel-button,
    &::search-results-button,
    &::search-results-decoration {
        display: none;
        width: 0;
        height: 0;
        opacity: 0; }
    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0; } }


a {
    color: inherit;
    text-decoration: none; }

img {
    width: auto;
    max-width: 100%;
    height: auto; }

a img {
    border: none; }

i,em {
    font-style: italic; }
b,strong {
    font-weight: 700; }
table {
    word-break: normal;
    border-collapse: collapse;
    border-spacing: 0; }

caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle; }

ol, ul {
    list-style: none; }

q, blockquote {
    quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
    content: "";
    content: none; }

::-moz-focus-inner {
    border: 0;
    padding: 0; }
