// Responsive

//maxWidth
@mixin max($width) {
	@media only screen and (max-width: $width+ "px") {
		@content; } }

// minWidth
@mixin min($width) {
	@media only screen and (min-width: $width+ "px") {
		@content; } }

// font face
@mixin font($font-family, $url, $weight, $style) {
    @font-face {
        font-family: '#{$font-family}';
        src: url('#{$url}.eot');
        src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
        url('#{$url}.woff') format('woff'),
        url('#{$url}.ttf') format('truetype') {}
        font-weight: $weight;
        font-style: $style; } }

// placeholder
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content; }

    &::-moz-placeholder {
        @content; }

    &:-moz-placeholder {
        @content; }

    &:-ms-input-placeholder {
        @content; } }
