
.swap-config-fields {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  padding: 0 !important;
}

.swap-rate-block {
  position: relative;

  .settings-icon {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 5px;
  }
}