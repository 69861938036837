.MuiInputBase-input {
	color: $primary !important;
	borderColor: $primary !important;
	&:hover, &:focused {
		borderColor: $primary !important; } }

.MuiFormLabel-root {
	color: $primary !important; }

.input-icon {
	position: absolute {
    right: 15px;
    top: 30px;
    cursor: pointer; } }

.input-focused, .input-notched {
	borderColor: $primary;
	&:hover {
		borderColor: $primary; } }

.input-label {
	&.input-focused {
		color: $primary; } }
