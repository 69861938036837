.color-nx {
    color: $colorNX !important;
    .MuiLinearProgress-barColorPrimary {
        background-color: $colorNX !important; }
    .MuiListItemText-primary {
        color: $colorNX !important; } }

.bg-nx {
    background-color: $colorNX !important;
    color: white !important;
    &.Mui-disabled {
        background-color: $lightGrey !important;
        color: black !important; } }

.fill-nx {
    fill: $colorNX !important; }

.forced-nx {
    .MuiTypography-colorPrimary {
        color: $colorNX !important; }
    .MuiButton-containedPrimary.MuiButton-root {
        background: $colorNX !important;
        &.Mui-disabled {
            background: $lightGrey !important; } }
    .MuiSlider-root {
        color: $colorNX !important; }
    fieldset {
        border-color: $colorNX !important;
        border-top-color: $colorNX !important;
        border-right-color: $colorNX !important;
        border-bottom-color: $colorNX !important;
        border-left-color: $colorNX !important; }
    label.Mui-focused {
        color: $colorNX !important; } }

.override-nx {
    .MuiFormLabel-root, .MuiInputBase-input, .MuiInputAdornment-root .MuiTypography-root, fieldset.MuiOutlinedInput-notchedOutline {
        color: $colorNX !important; } }

.input-nx {
    .MuiFormLabel-root.Mui-focused {
        color: $colorNX; }
    .MuiFormLabel-root.Mui-error {
        color: #f44336; }
    .MuiInputBase-root.MuiOutlinedInput-root fieldset {
        border-color: $colorNX !important; } }

.ewallet-actions {
    .MuiListItem-root {
        border-bottom: 1px solid $colorNX; } }

.nx-payment-loader {
     margin-top: 15em !important; }
.nx-partner-logo {
    img {
        max-width: 250px;
        margin-top: 10em;
        margin-bottom: 3em; }
    .nx-logo-next {
        font-size: 2em;
        width: 100%; } }

.table-heading-nx {
    background-color: $colorNX !important;
    position: relative !important;
    height: 85px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #fff !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    justify-content: center !important;
    display: flex !important;
    padding-left: 20px !important;
    padding-right: 55px !important; }

.btn-make-payment {
    z-index: 1000000;
    position: absolute !important;
    bottom: 70px;
    right: 20px; }

.widget-copy-right {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;

    .copy-right-container {
        align-items: center;
        color: #9a9c9f;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;

        .copy-right-border {
            background-color: #d9dbe0;
            flex-grow: 1;
            height: .5px; }

        .copy-right-box {
            margin: 0 5px; } } }
