html {
    min-height: 100%; }

body {
    padding-top: 0;
    font-family: "Mont", sans-serif;
    min-height: 500px;
    min-width: 300px;
    background-color: #f8f8f8; }

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden; }

.no-padding {
    padding: 0 !important; }

main {
    flex: 1 0 auto; }

.header {
    width: 100%;
    box-sizing: border-box;
    top: 0;
    background-color: #f8f8f8;

    min-height: 36px !important;
    max-height: 56px !important;
    padding-bottom: 0;

    z-index: 3;

    &__flex {
       flex-grow: 1;
       margin-left: 22px; }
    &__logo {
        margin-top: 5px;
        height: 25px; }

    &__menu-btn {
        position: absolute !important;
        width: 100%;
        top: 5px;
        background: none;
        box-shadow: none;
        svg {
            font-size: 26px; } }
    &__menu__item {
        padding-top: 45px !important; } }

.min-height-delta {
    min-height: calc( 100vh - 200px ); }

.view-paper {
    overflow: auto;
    overflow-wrap: break-word;
    -webkit-overflow-scrolling: touch;
    padding: 15px 10px 15px 10px;
    position: relative;
    z-index: 4;
    height: 87vh;

    display: grid;
    grid-template-rows: 1fr auto;

    &.scanning {
        background: transparent !important; }
    .MuiInputLabel-shrink {
        translate: (0, 2px) scale(0.75); } }
.push-bottom {
    height: 10em;
    display: inline-block; }

.scanner-alert {
    position: fixed;
    bottom: 5rem;
    left: 1.1rem;
    right: 1.1rem;
    z-index: 3500;
    width: auto !important; }

.scanner-message {
    padding-bottom: 1rem;
    background-color: white !important;
    color: $primary; }

.footer {
    position: fixed;
    width: 90%;
    margin-left: 5%;
    box-sizing: border-box;
    bottom: 5px;
    z-index: 2;
    &__copyright {
        text-align: center;
        font-size: 14px;
        padding-top: 15px;
        padding-bottom: 15px; } }

.bottom-scanner-action {
    .MuiBottomNavigationAction-label {
        opacity: 0.8 !important;
        margin-bottom: 4rem; } }

.home {
    max-width: 90vw;
    padding-top: 15px;
    padding-bottom: 25px;
    flex: 1;
    position: relative;

    &__bottom {
        text-align: center; } }

.spaced__btn.MuiButton-root {
    margin-top: 40px; }

.dashboard__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    &-icon {
        width: 55px; }
    &-icon-eth {
        width: 39px;
        margin-left: 5px;
        margin-right: 1px; }
    &-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        background-color: $primary;
        padding: 6px 0;
        margin-bottom: 5px;
        width: 100%;
        height: 90px;
        border-radius: 18px;
        flex-wrap: wrap;
        &-balance {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
        > h5 {
            margin: 0;
            font-family: "Mont", sans-serif;
            color: #fff;
            font-size: 25px;
            width: 100%;
            padding: 0 0 0 8px;
            font-weight: 700; }
        > h6 {
            font-weight: 500;
            font-size: 22px;
            color: #fff;
            font-family: "Mont", sans-serif; } } }

.balance-block-asset:nth-child(2) > .dashboard__actions-balance {
    border-top: 2px solid $primary; }

.dashboard__actions__flex__End {
    justify-content: flex-end; }

.dashboard__actions-balance {
    flex-direction: column;
    margin-bottom: 0; }

.dashboard__verificationMessge {
    text-align: center;
    padding-top: 5px;
    color: red;
    font-size: 14px; }

.register__error {
    color: red;
    text-align: center;
    padding-top: 20px;
    a {
        text-decoration: underline; } }

.register__success {
    color: green;
    text-align: center;
    padding-top: 20px; }

.loader {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }

.copy__code {
    position: relative;
    display: flex;
    height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: space-between;
    &__text {
        min-width: 260px;
        max-width: 70vw; }
    svg {
        background: #fff;
        fill: $primary;
        font-size: 20px;
        cursor: pointer;
        margin-left: 5px; } }

.coming-soon {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center; }

.noUpperText {
    text-transform: none;
    span {
        text-transform: none; } }

#dashboard-sending-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 70px); }

.hidden-input {
    display: none !important; }

.vp-height {
    min-height: 70vh; }

.simple-menu {
    right: 0 !important; }

.btn-circle {
    width: 48px !important;
    height: 48px !important;
    border-radius: 50% !important;
    background-color: $primary !important;
    &.Mui-disabled {
        background-color: lightgray !important; }
    .MuiButton-label .MuiSvgIcon-root {
        color: white; } }

.swipe-icon {
    font-size: 2.5rem !important;
    z-index: 1000;
    background-color: white;
    border: 2px solid;
    border-radius: 50%;
    position: absolute;
    &.left {
        left: 0;
        top: 40vh; }
    &.right {
        right: 0;
        top: 40vh; }
    &.down {
        top: 0;
        left: 40vw; } }
.swipe-down-grid {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem; }

.icon-aligned {
    display: inline-flex;
    vertical-align: top; }

.sidebar-toggle {
    label {
        margin-left: 0;
        .MuiSwitch-root {
            margin-left: 50px; } }
    &.small-padding {
        .MuiSwitch-root {
            margin-left: 25px; } } }

.checkbox-full {
    width: 95%;
    .MuiSvgIcon-root {
        color: rgba(0, 0, 0, 0.77); }
    .MuiFormControlLabel-label {
        font-size: 90%;
        color: rgba(0, 0, 0, 0.77); } }

.fix-to-bottom {
    bottom: 120px !important;
    position: absolute !important;
    width: 89% !important; }

.small-font {
    font-size: small; }

.wrap-padding {
    padding-left: 10px;
    padding-right: 10px; }

.exrate-container {
    &::before {
        content: "\f0c4";
        font-family: FontAwesome, sans-serif;
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        text-decoration: inherit; } }

.forced-primary {
    input, .MuiCircularProgress-svg {
        color: $primary !important; } }

.forced-bg-primary {
    color: white !important;
    background-color: $primary !important; }

#network-menu {
    margin-top: 2.5rem; }

.balance-total {
    padding-left: 1rem;
    padding-right: 1rem;
    #balance-total-usd,#iown-rate {
        color: $primary;
        font-weight: bold; } }

#balance-total-usd-label,#iown-rate-label {
    color: $primary; }

.spaced-top-s {
    margin-top: 1rem !important; }

.spaced-top {
    margin-top: 2em !important; }

.spaced-bottom {
    margin-bottom: 2em !important; }

.centered-grid {
    text-align: center; }

.left-grid {
    text-align: left !important; }

.relative-grid {
    position: relative; }

.balance-eth, .balance-bnb, .balance-matic {
    &:before {
        content: "Blockchain Balance (not included in Total)";
        border-top: 1px solid $primary;
        color: grey;
        text-align: center;
        font-size: x-small;
        margin-bottom: 20px;
        padding-top: 10px;
        margin-left: 2rem;
        margin-right: 2rem;
        width: 90%; }
    .dashboard__actions-text {
        background-color: #B0B0B0;
        h6 {
            font-weight: 500;
            font-size: 22px; } } }

.table-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #fff !important; }

.table-sub {
    font-family: "Mont", sans-serif  !important;
    font-size: 13px !important;
    margin: 5px 0 0 !important; }

.table-refresh-button {
    position: absolute !important;
    top: 20px !important;
    right: 10px !important; }

.table-refresh_icon {
    fill: #fff !important; }

.app-version {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    padding-left: 1.1rem;
    color: white; }
.app-version-home {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: block;
    color: lightgray; }

.btn-right-float {
    margin-left: 1em !important;
    padding: 0 !important;
    line-height: 1.5 !important;
    position: fixed !important;
    right: 10% !important; }

.network-badge {
    padding: 5px 0 5px 0;
    border-radius: 10px; }

.micro-text {
    font-size: 70%; }

.assets-accordion-root {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-around !important;
    overflow: hidden !important;

    .image-list {
        flex-wrap: nowrap !important;
        transform: translateZ(0) !important; }
    .title-bar {
        background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%); } }

#wert-module {
    height: 60vh;
    @media only screen and (max-width: 370px) {
        width: 98vw; } }
