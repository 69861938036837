.preloader-scan {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 200px;
  display: table;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  width: 100vw;

  .laser {
    width: 150%;
    margin-left: -25%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    animation: scanning 2s infinite; }

  .diode {
    animation: beam .01s infinite; } }

@keyframes beam {
  50% {
    opacity: 0; } }

@keyframes scanning {
  50% {
    transform: translateY(75px); } }

.qr-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

#qr-scan-img {
  position: absolute;
  top: 30%;
  left: 10%;
  width: 80vw;
  height: 40%; }

#shadow-backdrop {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #282828;
  filter: blur(8px);
  clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 30%, 90% 30%, 90% 70%, 10% 70%, 0% 100%, 100% 100%, 100% 0%); }

.fab-scanner {
  position: fixed !important;
  bottom: 0;
  right: 0; }
