//layouts
$mac: 1599;
$minDesk: 1280;
$tab: 1279;
$mob: 767;

//colors
$white: #ffffff;
$black: #000000;
$yellow: #ebcd41;
$blue: #0082ca;
$grey: #414141;
$lightGrey: #e0e0e0;
$dark-blue: #2a4f7c;

$primary: #0082BC;
$colorNX: #00499b;
$colorNX2: #1D2DE2;
$colorETH: #3498db;
$colorBSC: #F0B90B;
$colorMATIC: #8247e5;

$content-width: 1210px;
